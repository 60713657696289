<template>
  <div>
    <a-modal width="1300px" :title="Title" :visible="visible" :confirm-loading="confirmLoading" footer @cancel="cancel">
      <div style="height:600px;overflow:auto;padding:10px;">
        <a-form ref="formRef" :model="formState" :rules="rules">
          <a-row>
            <a-col :span="8">
              <a-form-item label="所属部门：" name="categoryId">
                <a-cascader v-model:value="formState.department" style="width:80%" allow-clear
                  :field-names="{ label: 'departmentName', value: 'departmentId', children: 'children' }"
                  :options="optionsCategory" change-on-select placeholder="请选择" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="培训结果：" name="TrainingResults">
                <a-select ref="select" style="width:80%" v-model:value="formState.status" allow-clear
                  :options="options">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="员工姓名：" name="name">
                <a-input v-model:value="formState.name" style="width:80%" allow-clear />
              </a-form-item>
            </a-col>
          </a-row>
          <div style="width: 100%;text-align: right;">
            <a-button @click="reset">
              重置
            </a-button>
            <a-button type="danger" @click="query">
              查询
            </a-button>
          </div>
        </a-form>
        <div style="margin: 10px 0;">
          <a-button type="default" @click="download" :disabled="sourceData.length==0">
            <VerticalAlignBottomOutlined />
            导出表格
          </a-button>
        </div>
        <a-spin :spinning="spinning">
          <a-table style="margin-top: 10px;height:500px;overflow: auto;" :columns="columns" :data-source="sourceData"
            :pagination="true" :row-selection="{ selectedRowKeys: trainingPlanUserIds, onChange: onSelectChange }"
            row-key="trainingPlanUserId">
            <!-- 考试成绩 -->
            <template #score1="{ text, record }">
              <span style="cursor: pointer; color: blue" @click="downloadTest(record, 0)">{{ text }}</span>
            </template>
            <!-- 考试结果 -->
            <template #TrainingResults="{ text, record }">
              <span :style="text === 1  ? 'color:#21C28E' : 'color:red'">
                {{ text === 1 ?'成绩合格':(text === 2 ?'成绩不合格':'未完成')}}
              </span>
            </template>
            <!-- 补考成绩 -->
            <template #score2="{ text, record }">
              <span style="cursor: pointer; color: blue" @click="downloadTest(record, 1)">{{ text }}</span>
            </template>
            <!-- 补考结果 -->
            <template #fillResults="{ text, record }">
              <span v-if="record.score2 !== null" :style="text === 1 ? 'color:#21C28E' : 'color:red'">
                {{ text === 1 ?'成绩合格':(text === 2 ?'成绩不合格':'未完成')}}</span>
            </template>
          </a-table>
        </a-spin>
      </div>
    </a-modal>
  </div>
</template>
<script>
  import { defineComponent } from "vue";
  import { TrainingPlanToUserController_find, TrainingPlanToUserController_updateStatus } from '@/api/api'
  import { departmentTree } from '@/api/system'
  import { genPdf } from '@/api/api'
  import { TrainingPlanToUserControllerDownload } from '@/api/downloadApi'
  import { blob } from '@/utils/blob'

  const columns = [
    {
      title: "序号",
      width: 60,
      dataIndex: "index",
      customRender: (row) => row.index + 1,
    },
    {
      title: '培训名称',
      dataIndex: 'trainingPlan.title',
    },
    {
      title: '部门',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: "姓名",
      dataIndex: "name",
    },
    {
      title: "身份证号",
      dataIndex: "identification",
    },
    {
      title: "培训学时",
      dataIndex: "trainingPlan.creditHours",
    },
    {
      title: "提交答题时间",
      dataIndex: "submitTime",
    },
    {
      title: "考试成绩",
      dataIndex: "score1",
      slots: { customRender: "score1" },
    },
    {
      title: "考试结果",
      dataIndex: "status1",
      slots: { customRender: "TrainingResults" },
    },
    {
      title: "补考成绩",
      dataIndex: "score2",
      slots: { customRender: "score2" },
    },
    {
      title: "补考结果",
      dataIndex: "status2",
      slots: { customRender: "fillResults" },
    },
  ];
  export default defineComponent({
    name: "index",
    props: {
      FormData: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        spinning: true,
        trainingPlanUserIds: [],
        record: {},
        categoryIds: [],
        optionsCategory: [],
        options: ([
          {
            value: "2",
            label: "成绩不合格",
          },
          {
            value: "1",
            label: "成绩合格",
          },
          {
            value: "0",
            label: "未完成",
          },
        ]),
        formState: {
          department: [],
          status:undefined,
          name:undefined
        },
        //来源 线上 线下
        source: "",
        rules: {},
        sourceData: [
        ],
        columns,
        visible: false,
        confirmLoading: false,
        Title: '',
        node: ''
      };
    },
    created() { },
    methods: {
      download() {
        TrainingPlanToUserControllerDownload(
          {
            trainingPlanId: this.record.trainingPlanId,
            ...this.formState,
            department: this.formState.department[this.formState.department.length - 1]
          }
        ).then(res => {
          blob(res)
        })
      },
      downloadTest(record, val) {
        const params = {
          examinationPlanId: record.trainingPlan.examinationPaper ? record.trainingPlan.examinationPaper : 0,
          trainingPlanId: record.trainingPlanId,
          examinationPaperId: record.trainingPlan.examinationPaperId ? record.trainingPlan.examinationPaperId : 0,
          retestNum: val,
          source: 3,
          userId: record.userId
        }
        genPdf(params).then(res => {
          window.open(res.data)
        })
      },
      onSelectChange(selectedRowKeys) {
        this.trainingPlanUserIds = selectedRowKeys;
      },
      updateStatus(val, status) {
        const params = {
          trainingPlanUserIds: this.trainingPlanUserIds,
          status: status
        }
        TrainingPlanToUserController_updateStatus(params).then(res => {
          this.api()
        })
      },
      cancel() {
        this.visible = false;
        this.reset()
      },
      reset() {
        this.formState = {
          department: [],
          status: undefined,
          name: undefined
        }
      },
      query() {
        this.api()
      },
      api() {
        this.trainingPlanUserIds = []
        this.sourceData = []
        const params = {
          trainingPlanId: this.record.trainingPlanId,
          ...this.formState,
          department: this.formState.department[this.formState.department.length - 1]
        }
        TrainingPlanToUserController_find(params).then(res => {
          this.spinning = false
          this.sourceData = res.data
        })
      },
      showModal(val, record, source) {
        this.source = source
        this.Title = val
        this.visible = true
        this.record = record
        this.spinning = true
        this.api()
        departmentTree({}).then(res => {
          this.optionsCategory = res.data
        })
      }
    },
  });
</script>
<style lang="less">

</style>