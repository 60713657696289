
  import { defineComponent, PropType, reactive, toRefs, ref, h, onMounted, getCurrentInstance, ComponentInternalInstance, createVNode, } from "vue";
  import pagaTable from "@/components/pagination-table/index";
  import { TransformCellTextProps } from "ant-design-vue/lib/table/interface";
  import formSearch from "@/components/page-search/form";
  import TrainingSituation from "./TrainingSituation.vue";
  import popupWindow from "./popupNew.vue";
  import { update } from "lodash";
  import { TrainingPlanControllerPage,TrainingPlanControllerStatisticsNum, find, TrainingPlanControllerUpdateStatus_put, TrainingPlanController_del } from "@/api/api"
  import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
  import { TrainingPlanController, TrainingPlanControllerDownloadSelectData } from '@/api/downloadApi'
  import { blob } from '@/utils/blob'
  import { message } from 'ant-design-vue';

  const purchaseColumns = [
    {
      title: "序号",
      width: 60,
      dataIndex: "index",
      customRender: (row: TransformCellTextProps) => row.index + 1,
    },
    {
      title: "计划名称 ",
      dataIndex: "title",
      width: 140,
      ellipsis: true,
    },
    {
      title: "培训类别",
      dataIndex: "category",
      width: 100,
    },

    {
      title: "培训时间",
      dataIndex: "",
      slots: { customRender: "Time" },
      width: 180,
    },
    {
      title: "状态",
      dataIndex: "status",
      width: 80,
      customRender: (row: TransformCellTextProps) =>
        row.text === 0 ? "未发布" : "已发布",
    },
    {
      title: "培训人数",
      dataIndex: "peopleNum",
      width: 100,
    },
    {
      title: "合格率",
      dataIndex: "completionRate",
      width: 100,
    },
    {
      title: "培训学时",
      dataIndex: "creditHours",
      width: 100,
    },
    {
      title: "创建人",
      dataIndex: "createdBy",
      width: 100,
    },
    {
      title: "创建时间",
      dataIndex: "createdTime",
      width: 150,
      sorter: true,
    },
    {
      title: "更新人",
      dataIndex: "updatedBy",
      width: 100,
    },
    {
      title: "更新时间",
      dataIndex: "updatedTime",
      width: 150,
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 200,
      slots: { customRender: "operation" },
    },
  ];
  export default defineComponent({
    name: "questionType",
    components: {
      pagaTable,
      popupWindow,
      TrainingSituation,
      formSearch,
    },
    setup(props: any) {
      const { proxy } = getCurrentInstance() as ComponentInternalInstance;
      const options = reactive([] as any[])
      const data = reactive({
        purchaseColumns,
        selectedRowKeys: [],
        extraModel: {},
        formBtn: {
          colProps: { span: 6 },
          formItemProps: {
            wrapperCol: {
              span: 24,
            },
          },
        },
        formConfig: [
          //试题描述：
          {
            colProps: {
              span: 6,
            },
            formItemProps: {
              label: "计划名称：",
            },
            comConfig: {
              tag: "input", // input, select, date-picker
              key: "title", // 即是插槽也是字段model key
              props: {
                placeholder: "请输入",
              },
            },
          },
          //试题类别：
          {
            rowProps: {},
            colProps: {
              span: 6,
            },
            formItemProps: {
              label: "培训类别：",
            },
            comConfig: {
              tag: "select",
              key: "categoryId",
              valueKey: "id",
              labelKey: "name",
              getDataApi: () => {
                return new Promise((reslove) => {
                  find({ type: 3 }).then(res => {
                    reslove(
                      res.data.map((res: { categoryId: any; name: any; }) => {
                        options.push({
                          value: res.categoryId,
                          label: res.name,
                        })
                        return { id: res.categoryId, name: res.name }
                      })
                    );
                  })
                });
              },
              props: {
                placeholder: "请输入",
              },
            },
          },

          // 更新时间
          {
            rowProps: {},
            colProps: {
              span: 6,
            },
            formItemProps: {
              label: "创建时间：",
            },
            comConfig: {
              tag: "input",
              key: "testSlots",
              props: {
                placeholder: "请输入",
              },
            },
          },
        ],
      });
      const onSelectChange = (selectedRowKeys: never[]) => {
        data.selectedRowKeys = selectedRowKeys;
      };
      const table = ref();
      const params_api = ref()
      const StatisticsNum=ref()
      const getDataApi = (params: any) => {
        
        return new Promise((reslove) => {
          params_api.value = {
            "currentPage": params.pageIndex,
            "pageSize": params.pageSize,
            ...formSearch.value.getQuery(),
            'startUpdateTime': updatedTime.value[0],
            'endUpdateTime': updatedTime.value[1],
            'type': 2,
            sort: params.sort
          }
          TrainingPlanControllerStatisticsNum({ ...params_api.value }).then(res=>{
            StatisticsNum.value=res.data
          })
          TrainingPlanControllerPage({ ...params_api.value }).then(res => {
            reslove(res)
          })
        });
      };
      function handleDownload() {
        TrainingPlanController({ ...params_api.value }).then(res => {
          blob(res)
        })
      }
      function downloadSelectApi() {
        if (data.selectedRowKeys.length > 0) {
          TrainingPlanControllerDownloadSelectData({ ids: data.selectedRowKeys }).then(res => {
            blob(res)
          })
        } else {
          message.warning('请先选择需要下载的内容');
        }
      }
      const handleChange = (
        pagination: any,
        filter: any,
        sorter: any,
        { currentDataSource }: any
      ) => {
        console.log(pagination, filter, sorter, currentDataSource);
      };
      const handleClick = (text: string, row: any) => {
        Title.value = "编辑线上培训计划";
        popupWindow.value.showModal(row.trainingPlanId);
      };
      const formSearch = ref();
      const popupWindow = ref();
      const TrainingSituation = ref();
      const Title = ref();
      const updatedTime = ref([]);
      const handleAdd = () => {
        Title.value = "新增线上培训计划";
        popupWindow.value.showModal();
      };
      const refresh = () => {
        table.value.refresh();
        data.selectedRowKeys = []
      }
      const handleSearch = (formState: any) => {
        // formSearch.value.getQuery();
        // console.log({ ...formSearch.value.getQuery() });
        // console.log("插槽", { ...updatedTime.value });
        refresh();
      };
      const TrainingSituationClick = (record: any) => {
        TrainingSituation.value.showModal("培训情况", record, 'onlineTraining');
      };
      const handleDeleteAll = () => {
        (proxy as any).$confirm({
          title: "删除确认",
          icon: () => createVNode(ExclamationCircleOutlined),
          content: "确认删除选中试题吗？",
          okType: " ",
          onOk: () => {
            return new Promise((resolve) => {
              TrainingPlanController_del({ id: data.selectedRowKeys.join(','), type: 2 }).then(() => {
                data.selectedRowKeys = []
              })
                .finally(() => {
                  refresh();
                  resolve(true)
                });
            });
          },
        });
      };
      const handleUpdateStatus = (status: string) => {
        const statusName = status === '1' ? '发布' : '取消发布';
        (proxy as any).$confirm({
          title: `${statusName}确认`,
          icon: () => createVNode(ExclamationCircleOutlined),
          content: `确认${statusName}选中试题吗？`,
          onOk: () => {
            return new Promise((resolve) => {
              TrainingPlanControllerUpdateStatus_put({
                status,
                trainingPlanIds: data.selectedRowKeys
              }).then(() => {
                (proxy as any).$message.success(`试题${statusName}成功`);
              })
                .finally(() => {
                  resolve(true);
                  refresh();
                })
            });
          },
        });
      }
      const reset = () => {
        updatedTime.value = []
      };
      return {
        StatisticsNum,
        handleDownload,
        downloadSelectApi,
        reset,
        handleDeleteAll,
        handleUpdateStatus,
        refresh,
        TrainingSituationClick,
        ...toRefs(data),
        table,
        onSelectChange,
        getDataApi,
        handleChange,
        handleClick,
        formSearch,
        popupWindow,
        TrainingSituation,
        Title,
        handleAdd,
        handleSearch,
        updatedTime,
      };
    },
  });
