<template>
  <div>
    <a-modal width="1100px" :title="Title" :visible="visible" :confirm-loading="confirmLoading" footer @cancel="cancel">
      <div style="height:600px;overflow:auto;padding:10px;">
        <a-form ref="formRef" :model="formState" :rules="rules">
          <a-row>
            <a-col :span="8">
              <a-form-item label="考卷分类：">
                <a-select ref="select" style="width:80%" v-model:value="formState.type" allowClear :options="options2">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="考卷名称：">
                <a-input v-model:value="formState.title" allowClear style="width:80%" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="20" />
            <a-col :span="4">
              <a-button @click="reset">重置</a-button>
              <a-button type="primary" @click="query">
                查询
              </a-button>
            </a-col>
          </a-row>
          <a-table style="margin-top: 10px" :columns="columns" :data-source="sourceData" :pagination="false">
            <template #operation="{ text, record, index }">
              <div v-if="record.status == 1 && checkIds.length == 0">
                <a-button type="link" style=" " @click="add(record)" v-if="handleGetShow(record)">添加</a-button>
              </div>
            </template>
          </a-table>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { ExaminationPaperController_find, find } from "@/api/api"

const columns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row) => row.index + 1,
  },
  {
    title: "考卷名称 ",
    dataIndex: "title",
    width: 200,
    ellipsis: true,
  },
  {
    title: "考卷总分",
    dataIndex: "totalPoints",
  },
  {
    title: "考卷类型",
    dataIndex: "type",
    customRender: (text) => {
      return (text.text == 1 ? "选题组卷" : "随机组卷")
    },
  },
  {
    title: "考卷状态",
    dataIndex: "status",
    customRender: (text) => {
      return (text.text == 0 ? "未发布" : "已发布")
    },
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: 200,
    slots: { customRender: "operation" },
  },
];
export default defineComponent({
  name: "index",
  inject: ['addTable'],
  props: {
    checkIds: String,
    FormData: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      index: '',
      options2: [{
        value: 1,
        label: "选题组卷",
      },
      {
        value: 2,
        label: "随机组卷",
      },],
      Title: '',
      selectedRowKeys: [],
      sourceData: [],
      columns,
      visible: false,
      confirmLoading: false,
      formState: {
        title: '',
        type: ''
      },
      rules: {
        title: [{ required: true, message: '不能为空', trigger: 'blur' }],
      }
    };
  },
  methods: {
    reset() {
      this.formState = {
        title: '',
        type: ''
      }
    },
    query() {
      ExaminationPaperController_find(this.formState).then(res => {
        this.sourceData = res.data
      })
    },
    cancel() {
      this.formState = {
        title: '',
        type: ''
      }
      this.visible = false;
    },
    add(row) {
      this.addTable(3, this.index, [row])
    },
    showModal(index, val) {
      this.index = index
      this.Title = val
      this.visible = true;
      ExaminationPaperController_find().then(res => {
        this.sourceData = res.data
      })
    },
    handleGetShow(row) {
      return !this.checkIds.includes(row.examinationPaperId)
    },
  },
});
</script>
<style lang="less">

</style>
      