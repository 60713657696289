<template>
  <div>
    <a-modal
      width="1000px"
      :title="Title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="cancel"
    >
      <div style="height:600px;overflow:auto;padding:10px;">
        <a-form
          ref="formRef"
          :model="formState"
          :rules="rules"
        >
          <div style="width:100%;border: 1px solid rgb(225, 225, 225);padding:10px;">
            <div style="height:40px;line-height: 40px;background:rgb(225, 225, 225);">
              <h2 style="margin-left:10px">培训基本信息</h2>
            </div>
            <a-row style="margin-top:5px">
              <a-col :span="12">
                <a-form-item
                  label="培训标题："
                  name="title"
                >
                  <a-input
                    v-model:value="formState.title"
                    style="width:80%"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                  label="参训人员："
                  name="trainingPlanToUsers"
                >
                  <a-button @click="trainees">选择</a-button>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row style="margin-top:5px">
              <a-col :span="12">
                <a-form-item
                  label="培训类型："
                  name="categoryId"
                >
                  <a-select
                    ref="select"
                    style="width:80%"
                    v-model:value="formState.categoryId"
                    :options="options"
                  >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                  label="起止时间："
                  name="time"
                >
                <a-range-picker v-model:value="formState.time" :show-time="{
                    hideDisabledOptions: true,
                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
                  }" value-format="YYYY-MM-DD HH:mm:ss" />
                </a-form-item>
              </a-col>

            </a-row>
            <a-row style="margin-top:5px">
              <a-col :span="12">
                <a-form-item
                  label="封面图片："
                  name="img"
                >
                  <uploadPictures
                    ref="uploadPictures"
                    :FormData="formState"
                    @handleChange="handleChangeUploadPictures"
                  >
                  </uploadPictures>
                  <span style="margin-left:10px">封面格式为JPG、PNG格式，尺寸为4:3，大小不超过1M</span>
                </a-form-item>
              </a-col>
            </a-row>

          </div>
          <div style="width:100%;border: 1px solid rgb(225, 225, 225);padding:10px;position:relative;">
            <div style="height:40px;line-height: 40px;background:rgb(225, 225, 225);">
              <h2 style="margin-left:10px">培训资料信息</h2>
            </div>
            <a-button
              style="position:relative;top:5px;left:85%"
              @click="add"
              type="primary"
            >绑定文件</a-button>
            <a-table
              style="margin-top: 10px"
              :columns="columns"
              :data-source="sourceData"
              :pagination="false"
              row-key="categoryId"
            >
              <template #operation="{ text, record, index }">
                <a-button
                  type="link"
                  style=" "
                  @click="delClick(index)"
                >删除</a-button>
              </template>
            </a-table>
          </div>
          <div style="width:100%;border: 1px solid rgb(225, 225, 225);padding:10px;position:relative;margin-top:10px;">
            <div style="height:40px;line-height: 40px;background:rgb(225, 225, 225);">
              <h2 style="margin-left:10px">培训考试信息</h2>
            </div>
            <a-button
              v-if="sourceDataPaper.length < 1"
              style="position:relative;top:5px;left:85%"
              @click="addPaper"
              type="primary"
            >绑定考卷</a-button>
            <a-table
              style="margin-top: 10px;margin-bottom: 10px"
              :columns="columns1"
              :data-source="sourceDataPaper"
              :pagination="false"
            >
              <template #operation="{ text, record, index2 }">
                <a-button
                  type="link"
                  style=" "
                  @click="delPaper(index, index2)"
                >删除</a-button>
              </template>
            </a-table>
            <a-form-item
              v-if="sourceDataPaper.length > 0"
              label="考试时长（Min)："
              name="duration"
            >
              <a-time-picker
                v-model:value="formState.duration"
                value-format="HH:mm:ss"
                :default-value="'00:01:00'"
              />
            </a-form-item>
            <a-form-item
              v-if="sourceDataPaper.length > 0"
              label="及格分数："
              name="score"
            >
              <a-input-number
                v-model:value="formState.score"
                style="width:30%"
              />
            </a-form-item>
          </div>
        </a-form>
      </div>
      <popupTrainees
        ref="popupTrainees"
        @sourceData="popupTraineesSourceData"
        :FormData="formState.trainingPlanToUsers"
      >
      </popupTrainees>
      <popupBindingFile
        ref="popupBindingFile"
        :checkIds="checkIds"
      ></popupBindingFile>
      <popupbindingPaper
        ref="popupbindingPaper"
        :checkIds="checkIdsPaper"
      ></popupbindingPaper>
    </a-modal>
  </div>
</template>
<script>
import uploadPictures from "../../components/uploadPictures";
import popupTrainees from "../../components/popupTrainees"
import popupBindingFile from './popupBindingFile.vue'
import popupbindingPaper from "./popupbindingPaper.vue";
import { defineComponent } from "vue";
import { message } from "ant-design-vue";

import moment from 'moment';
import { find, TrainingPlanController_post, TrainingPlanController_id, TrainingPlanController_put } from '@/api/api'
const columns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row) => row.index + 1,
  },
  {
    title: "资料名称 ",
    dataIndex: "title",
    width: 200,
    ellipsis: true,
  },
  {
    title: "资料类别",
    dataIndex: "category",
  },
  {
    title: "文档类型",
    dataIndex: "type",
    customRender: (row) =>
      row.text === 1 ? "文档" : "视频",
  },
  {
    title: "资料状态",
    dataIndex: "status",
    customRender: (row) =>
      row.text === 0 ? "未发布" : "已发布",
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: 200,
    slots: { customRender: "operation" },
  },
];
const columns1 = [{
  title: "序号",
  width: 60,
  dataIndex: "index",
  customRender: (row) => row.index + 1,
},
{
  title: "考卷名称 ",
  dataIndex: "title",
  width: 200,
  ellipsis: true,
},
{
  title: "考卷总分",
  dataIndex: "totalPoints",
},
{
  title: "考卷类型",
  dataIndex: "type",
  customRender: (text) => {
    return (text.text == 1 ? "选题组卷" : "随机组卷")
  },
},
{
  title: "考卷状态",
  dataIndex: "status",
  customRender: (text) => {
    return (text.text == 0 ? "未发布" : "已发布")
  },
},
{
  title: "操作",
  dataIndex: "operation",
  width: 200,
  slots: { customRender: "operation" },
},]
export default defineComponent({
  name: "index",
  props: {
    Title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      moment,
      checkIdsPaper: [],
      editor: false,
      sourceData: [],
      sourceDataPaper: [],
      options: [],
      columns,
      columns1,
      visible: false,
      confirmLoading: false,
      formState: {
        score:'',
        duration: '00:01:00',
        categoryId: '',
        trainingPlanToUsers: [],
        title: '',
        time: [],
        img: ''
      },
      rules: {
        title: [{ required: true, message: '不能为空', trigger: 'blur' }],
        categoryId: [{ required: true, message: '不能为空', trigger: 'blur' }],
        img: [{ required: true, message: '不能为空', trigger: 'blur' }],
        duration:[{ required: true, message: '不能为空', trigger: 'blur' }],
        score: [{
          required: true, message: '不能为空', trigger: 'change', transform: (value) => {
            if (value) {
              return value.toString()
            }
            return value
          }
        }],
        trainingPlanToUsers: [{
          required: true, message: '不能为空', trigger: 'change', transform: (value) => {
            if (value.length > 0) {
              return value.toString()
            }
            return value
          }
        }],
        time: [{
          required: true, message: '不能为空', trigger: 'change', transform: (value) => {
            if (value.length > 0) {
              return value.toString()
            }
            return value
          }
        }],
      },
    };
  },
  provide() {
    return {
      addTable: this.addTable,
    }
  },
  components: {
    uploadPictures,
    popupTrainees,
    popupBindingFile,
    popupbindingPaper
  },
  created() { },
  computed: {
    checkIds() {
      return this.sourceData.map(item => item.materialId)
    }
  },
  methods: {
    popupTraineesSourceData(val) {
      this.formState.trainingPlanToUsers = val
    },
    cancel() {
      this.$refs.formRef.resetFields()
      // this.formState = {
      //   categoryId: '',
      //   trainingPlanToUsers: [],
      //   title: '',
      //   time: [],
      //   img: ''
      // }
      this.sourceDataPaper = [],
        this.sourceData = [],
        this.visible = false;
    },
    //上传封面图片回调
    handleChangeUploadPictures(info) {
      if(info.response.data?.path){
        // this.formState.img = 'http://qyapi.ruanwe.com'+  info.response?.data.path
        this.formState.img = info.response?.data.path
      }else{
        this.$message.error('上传服务器失败，请重新上传')
      }
    },
    add() {
      this.$refs.popupBindingFile.showModal('绑定文件')
    },
    trainees() {
      this.$refs.popupTrainees.showModal('参训人员')
    },
    delPaper(index) {
      this.sourceDataPaper.splice(index, 1)
      this.formState.score = ''
      this.formState.duration = '00:01:00'
    },
    addPaper(index) {
      this.checkIdsPaper = this.sourceDataPaper.map(item => item.examinationPlanId)
      this.$refs.popupbindingPaper.showModal(index, '绑定考试计划')
    },
    addTable(number, index, arr) {
      if (number == 1) {
        this.sourceData.push(...arr)
      }
      if (number == 3) {
        this.sourceDataPaper.push(...arr)
        this.checkIdsPaper = this.sourceDataPaper.map(item => item.examinationPlanId)
      }
    },
    delClick(index) {
      this.sourceData.splice(index, 1)
    },
    handleOk() {
      if(this.sourceData.length==0){
        message.error('请绑定资料');
      }else if(this.sourceDataPaper.length==0){
        message.error('请绑定考卷');
      }else{
        this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true
        const params = {
          ...this.formState,
          startDate: this.formState.time[0],
          endDate: this.formState.time[1],
          trainingPlanToMaterials: this.sourceData,
          examinationPaperId: this.sourceDataPaper[0] ? this.sourceDataPaper[0]?.examinationPaperId : '',
          type: 2,
        }
        if (!this.editor) {
          TrainingPlanController_post(params).then(res => {
            this.cancel()
            this.confirmLoading = false;
            this.$parent.refresh()
          })
        } else {
          TrainingPlanController_put(params).then(res => {
            this.cancel()
            this.confirmLoading = false;
            this.$parent.refresh()
          })
        }
      });
      }
    },
    showModal(val) {
      this.editor = false
      if (val) {
        this.editor = true
        TrainingPlanController_id(val).then(res => {
          this.formState = { ...this.formState, ...res.data }
          this.formState.time.push(this.formState.startDate)
          this.formState.time.push(this.formState.endDate)
          this.formState.trainingPlanToUsers = []
          res.data.trainingPlanToUsers.forEach(res => {
            this.formState.trainingPlanToUsers.push({ id: res.id, realName: res.name, department: res.department })
          })
          if (res.data.examinationPaper) {
            this.sourceDataPaper.push(res.data.examinationPaper)
          } else {
            this.sourceDataPaper = []
          }
          this.sourceData = res.data.materials
          this.visible = true;
          this.$nextTick(() => {
            this.$refs.uploadPictures.init()
          })
        })
      } else {
        this.editor = false
        this.visible = true;
        this.formState = {
          score:'',
          duration: '00:01:00',
          categoryId: '',
          trainingPlanToUsers: [],
          title: '',
          time: [],
          img: ''
        }
        this.$nextTick(() => {
          this.$refs.uploadPictures.init()
        })
      }
      this.options = []
      this.sourceData = []
      find({ type: 3 }).then(res => {
        res.data.map(res => {
          this.options.push({
            value: res.categoryId,
            label: res.name,
          })
        })
      })
    },
  },
});
</script>
<style lang="less">
.table_h2 {
  width: 100%;
  border: 1px solid rgb(225, 225, 225);
}
</style>
    